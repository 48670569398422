import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  "Clean Code": "3db0a2017b59",
  "Programming Paradigms": "3db0a2017b59",
  OOP: "3db0a2017b59",
  "Design Principles": "3db0a2017b59",
  "Design Pattens": "3db0a2017b59",
  "Architecture Patterns": "3db0a2017b59",
  "Architecture Styles": "3db0a2017b59",
  "Enterprise Styles": "3db0a2017b59",

  "Mimari-Nedir": "6f28c34badfa",
  "Mimari-Tanım": "6f28c34badfa",
  "Mimari-Yapıyı Belirler": "6f28c34badfa",
  "Mimari-Davranışı Belirler": "6f28c34badfa",
  "Mimari-Önemli Öğeler": "6f28c34badfa",
  "Mimari-Paydasları Dengeler": "6f28c34badfa",
  "Mimari-Mantığa Dayalı Kararlar": "6f28c34badfa",
  "Mimari-Mimari Stil": "6f28c34badfa",
  "Mimari-Çevre": "6f28c34badfa",
  "Mimari-Ekip Yapısı": "6f28c34badfa",
  "Mimari-Belgeleme": "6f28c34badfa",
  "Mimari-Kapsam": "6f28c34badfa",

  Planlama: "ad8a5c5b9684",
  Analiz: "ad8a5c5b9684",
  Tasarım: "ad8a5c5b9684",
  Gerçekleştirme: "ad8a5c5b9684",
  Bakım: "ad8a5c5b9684",

  "Code&Fix": "62915545c51e",
  Waterfall: "62915545c51e",
  "V-Model": "62915545c51e",
  Evolutionary: "62915545c51e",
  Prototyping: "62915545c51e",
  Spiral: "62915545c51e",
  Incremental: "62915545c51e",
  UML: "62915545c51e",
  Agile: "62915545c51e",

  XP: "dc08d11b2fa",
  Kanban: "dc08d11b2fa",
  Scrum: "dc08d11b2fa",

  "Agile Manifesto": "8fe79b59aff4",

  "Conway Yasası": "a03810322811",
  "Amazon Two Pizza Teams": "a03810322811",
  "Min Value Product": "a03810322811",
  "Jobs To Be Done": "a03810322811",
  "Customer Development": "a03810322811",
  "FDD,BDD,DDD,MDD": "a03810322811",
  BrainStorming: "a03810322811",
  Prototyping2: "a03810322811",
  MindMap: "a03810322811",
  UML2: "a03810322811",
  "Design System": "a03810322811",

  "Imperative Programming": "a03810322811",
  "Declerative Programming": "a03810322811",
  "OO Programming": "a03810322811",
  "Aspect Oriented Programming": "a03810322811",

  SOLID: "a03810322811",
  KISS: "a03810322811",
  DRY: "a03810322811",
  YAGNI: "a03810322811",
  TDD: "a03810322811",
  "Design Patterns": "a03810322811",
  "Architectural Pattens": "a03810322811",
  "Integration Pattens": "a03810322811",
  "Pair Programming": "a03810322811",

  "Reactive Manifesto": "a03810322811",
  "12 Factor Apps": "a03810322811",

  "AWS Well-Architected": "c686c44a5eb3",
  Pillars: "c686c44a5eb3",
  "Design Principle": "c686c44a5eb3",

  "Horizontal Slicing": "91c8a559af32",
}

const stories = [
  {
    title: "Yazılım Mimarisi Yol Haritası",
    postId: "3db0a2017b59",
  },
  {
    title: "Yazılım Mimarisi Nedir",
    postId: "6f28c34badfa",
  },
  {
    title: "Yazılım Geliştirme Fazları",
    postId: "ad8a5c5b9684",
  },
  {
    title: "Yazılım Geliştirme Modelleri",
    postId: "62915545c51e",
  },
  {
    title: "Agile Frameworks (Kanban, XP, Scum)",
    postId: "dc08d11b2fa",
  },
  {
    title: "Çevik Bildiri (Agile Manifesto)",
    postId: "8fe79b59aff4",
  },
  {
    title: "Yazılım Geliştirme Prensipleri",
    postId: "a03810322811",
  },
  {
    title: "AWS Well-Architected Framework",
    postId: "c686c44a5eb3",
  },
  {
    title: "Horizontal / Vertical Slicing",
    postId: "91c8a559af32",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Mimari Kavramlar",
  path: "mimari-kavramlar",
}

const ArchExperiencePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ArchExperiencePage
